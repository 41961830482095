<template>
  <div v-if="reservation">
    <ModalDeleteCancelRule
      v-if="showDeleteCancelRule"
      :showModal="showDeleteCancelRule"
      :onClickCancel="hideModal"
    />

    <table class="table is-fullwidth is-striped is-narrow">
      <tbody>
        <tr>
          <td>Total excl. VAT</td>
          <td class="has-text-right">
            {{
              reservation.TotalExclTax
                | toCurrency('en', reservation.CurrencyIso)
            }}
          </td>
          <td width="85" class="is-hidden-print">&nbsp;</td>
        </tr>
        <template v-for="total in reservation.TaxTotals">
          <tr :key="total.Percentage" v-if="total.Total > 0">
            <td
              :class="{
                'has-text-warning has-text-weight-bold': total.Percentage === 0,
              }"
            >
              Total VAT
              <span
                :class="{
                  'has-text-warning has-text-weight-bold':
                    total.Percentage === 0,
                }"
                >{{ total.Percentage }}%</span
              >
            </td>
            <td class="has-text-right">
              {{ total.Total | toCurrency('en', reservation.CurrencyIso) }}
            </td>
            <td width="85" class="is-hidden-print">&nbsp;</td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td>Total incl. VAT</td>
          <td width="100" class="has-text-right">
            {{
              reservation.TotalInclTax
                | toCurrency('en', reservation.CurrencyIso)
            }}
          </td>
          <td width="85" class="is-hidden-print">&nbsp;</td>
        </tr>

        <tr v-if="reservation.CancelRule">
          <td class="has-text-weight-bold">
            {{
              $t(
                'Components.Reservation.ReservationTotals.Label_CancelRuleApplies'
              )
            }}
            {{ reservation.CancelRule.Percentage }}%
            {{ $t('Components.Reservation.ReservationTotals.Label_Of') }}
            {{
              reservation.TotalInclTax
                | toCurrency('en', reservation.CurrencyIso)
            }}
          </td>
          <td width="100" class="has-text-left">&nbsp;</td>
          <td width="85" class="is-hidden-print">
            <a @click="setShowDeleteCancelRule" class="is-small">
              <span class="icon has-text-danger">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
            </a>
          </td>
        </tr>
        <tr v-if="reservation.Cancel && reservation.Cancel.Percentage > 0">
          <td class="has-text-weight-bold">Amount due</td>
          <td width="100" class="has-text-right">
            {{
              reservation.AmountToPay
                | toCurrency('en', reservation.CurrencyIso)
            }}
          </td>
          <td width="85">&nbsp;</td>
        </tr>
        <tr v-if="reservation.Cancel && reservation.Cancel.Percentage > 0">
          <td colspan="3">
            <Message
              :message="
                $t(
                  'Components.Reservation.ReservationTotals.Label_CancelRuleApplies',
                  {
                    percentage: reservation.Cancel.Percentage,
                  }
                )
              "
              :type="'is-danger'"
            />
          </td>
        </tr>

        <tr v-if="reservation.TotalPaid > 0">
          <td>Total paid</td>
          <td width="100" class="has-text-right">
            {{
              reservation.TotalPaid | toCurrency('en', reservation.CurrencyIso)
            }}
          </td>
          <td width="85" class="is-hidden-print">&nbsp;</td>
        </tr>

        <tr v-if="reservation.TotalPaid > 0">
          <td class="has-text-weight-bold">Amount open</td>
          <td class="has-text-right has-text-weight-bold">
            {{
              reservation.TotalOpen | toCurrency('en', reservation.CurrencyIso)
            }}
          </td>
          <td width="85" class="is-hidden-print">&nbsp;</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalDeleteCancelRule = () =>
  import('@/components/Reservations/ModalDeleteCancelRule')

export default {
  components: {
    Message,
    ModalDeleteCancelRule,
  },

  props: {},

  data() {
    return {
      showDeleteCancelRule: false,
      taxValues: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    setShowDeleteCancelRule() {
      this.showDeleteCancelRule = true
    },

    hideModal() {
      this.showDeleteCancelRule = false
    },
  },
}
</script>
