var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.reservation)?_c('div',[(_vm.showDeleteCancelRule)?_c('ModalDeleteCancelRule',{attrs:{"showModal":_vm.showDeleteCancelRule,"onClickCancel":_vm.hideModal}}):_vm._e(),_c('table',{staticClass:"table is-fullwidth is-striped is-narrow"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Total excl. VAT")]),_c('td',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.reservation.TotalExclTax,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{staticClass:"is-hidden-print",attrs:{"width":"85"}},[_vm._v(" ")])]),_vm._l((_vm.reservation.TaxTotals),function(total){return [(total.Total > 0)?_c('tr',{key:total.Percentage},[_c('td',{class:{
              'has-text-warning has-text-weight-bold': total.Percentage === 0,
            }},[_vm._v(" Total VAT "),_c('span',{class:{
                'has-text-warning has-text-weight-bold':
                  total.Percentage === 0,
              }},[_vm._v(_vm._s(total.Percentage)+"%")])]),_c('td',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(total.Total,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{staticClass:"is-hidden-print",attrs:{"width":"85"}},[_vm._v(" ")])]):_vm._e()]})],2),_c('tfoot',[_c('tr',[_c('td',[_vm._v("Total incl. VAT")]),_c('td',{staticClass:"has-text-right",attrs:{"width":"100"}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.reservation.TotalInclTax,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{staticClass:"is-hidden-print",attrs:{"width":"85"}},[_vm._v(" ")])]),(_vm.reservation.CancelRule)?_c('tr',[_c('td',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t( 'Components.Reservation.ReservationTotals.Label_CancelRuleApplies' ))+" "+_vm._s(_vm.reservation.CancelRule.Percentage)+"% "+_vm._s(_vm.$t('Components.Reservation.ReservationTotals.Label_Of'))+" "+_vm._s(_vm._f("toCurrency")(_vm.reservation.TotalInclTax,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{staticClass:"has-text-left",attrs:{"width":"100"}},[_vm._v(" ")]),_c('td',{staticClass:"is-hidden-print",attrs:{"width":"85"}},[_c('a',{staticClass:"is-small",on:{"click":_vm.setShowDeleteCancelRule}},[_c('span',{staticClass:"icon has-text-danger"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash-alt']}})],1)])])]):_vm._e(),(_vm.reservation.Cancel && _vm.reservation.Cancel.Percentage > 0)?_c('tr',[_c('td',{staticClass:"has-text-weight-bold"},[_vm._v("Amount due")]),_c('td',{staticClass:"has-text-right",attrs:{"width":"100"}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.reservation.AmountToPay,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{attrs:{"width":"85"}},[_vm._v(" ")])]):_vm._e(),(_vm.reservation.Cancel && _vm.reservation.Cancel.Percentage > 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('Message',{attrs:{"message":_vm.$t(
                'Components.Reservation.ReservationTotals.Label_CancelRuleApplies',
                {
                  percentage: _vm.reservation.Cancel.Percentage,
                }
              ),"type":'is-danger'}})],1)]):_vm._e(),(_vm.reservation.TotalPaid > 0)?_c('tr',[_c('td',[_vm._v("Total paid")]),_c('td',{staticClass:"has-text-right",attrs:{"width":"100"}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.reservation.TotalPaid,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{staticClass:"is-hidden-print",attrs:{"width":"85"}},[_vm._v(" ")])]):_vm._e(),(_vm.reservation.TotalPaid > 0)?_c('tr',[_c('td',{staticClass:"has-text-weight-bold"},[_vm._v("Amount open")]),_c('td',{staticClass:"has-text-right has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.reservation.TotalOpen,'en', _vm.reservation.CurrencyIso))+" ")]),_c('td',{staticClass:"is-hidden-print",attrs:{"width":"85"}},[_vm._v(" ")])]):_vm._e()])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }